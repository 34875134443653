import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';
import { LatestResourcesFragment } from '../../../wp-generated/types';
import LinkButton from '../../LinkButton';
import {
  chooseButtonColorBasedOnBackground,
  chooseLightOrDarkTextColor,
  moduleBackgroundToCSS,
} from '../getModuleColors';

const ModuleContainer = styled.section<{ $background: string; $color: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ $background }) => $background};
  color: ${({ $color }) => $color};
  gap: 1rem;
  padding: 1.25rem;
  @media (min-width: 1080px) {
    padding: var(--spacing-2x-large) var(--site-padding);
  }
  h2 {
    font-size: var(--font-size-large);
    margin: 0 auto;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const ArticleList = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  justify-content: center;
  gap: 1.5rem;
  @media (min-width: 1080px) {
    gap: 2.5rem;
  }
`;

const Article = styled.article`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  a {
    color: inherit;
    width: fit-content;
    transform: translateX(-1rem);
    &:hover {
      text-decoration: underline;
      color: inherit;
    }
  }
  h3 {
    margin: 0;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
`;

const PostTags = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: var(--spacing-small);
`;

const PostTag = styled.a<{ $background: string }>`
  position: relative;
  display: inline-block;
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  font-size: var(--font-size-x-small);
  padding: 3px 8px;
  border-radius: 2rem;
  line-height: 1.2;
  overflow: hidden;
  color: var(--color-white);
  z-index: 1;
  opacity: 0.8;
  transition: opacity var(--anim-speed) var(--easing);

  &:hover {
    opacity: 1;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${({ $background }) =>
      $background === 'transparent' ? 'var(--color-white)' : $background};
    filter: brightness(0.55);
    z-index: -1;
  }
`;

const ModuleFooter = styled.footer`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const LatestResources = ({ moduleData }: { moduleData: LatestResourcesFragment }) => {
  const { t } = useTranslation();

  return (
    <ModuleContainer
      $background={moduleBackgroundToCSS(moduleData.bgColor)}
      $color={chooseLightOrDarkTextColor(moduleData.bgColor)}
    >
      <h2>{moduleData?.title}</h2>
      <ArticleList>
        {moduleData?.data?.map(post => (
          <Article key={`${post?.title}-${post?.url}`}>
            <ImgWrapper>
              <Img src={post?.image || ''} alt="" role="presentation" />
            </ImgWrapper>
            {post?.tags && post?.tags.length > 0 && (
              <PostTags>
                {post?.tags?.map((tag, index) => (
                  <PostTag
                    key={index}
                    href={tag?.url || ''}
                    $background={moduleBackgroundToCSS(moduleData.bgColor)}
                  >
                    {tag?.name}
                  </PostTag>
                ))}
              </PostTags>
            )}
            <h3>{post?.title}</h3>
            {post?.excerpt && <p>{post?.excerpt || ''}</p>}
            <LinkButton href={post?.url || ''} variant="text">
              {t('common_read-more')}
            </LinkButton>
          </Article>
        ))}
      </ArticleList>
      {moduleData?.links && moduleData?.links.length > 0 && (
        <ModuleFooter>
          {moduleData?.links.map((link, i) => (
            <LinkButton
              variant={chooseButtonColorBasedOnBackground(moduleData.bgColor)}
              href={link?.link?.url || ''}
              key={i}
              target={link?.link?.target || undefined}
            >
              {link?.link?.title || ''}
            </LinkButton>
          ))}
        </ModuleFooter>
      )}
    </ModuleContainer>
  );
};

export default LatestResources;
