import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';
import { CustomerCasesFragment, ImageFragment } from '../../../wp-generated/types';
import LinkButton from '../../LinkButton';
import PostImage from '../../PostImage/PostImage';
import {
  chooseButtonColorBasedOnBackground,
  chooseLightOrDarkTextColor,
  moduleBackgroundToCSS,
} from '../getModuleColors';

const ModuleContainer = styled.section<{ $background: string; $color: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ $background }) => $background};
  color: ${({ $color }) => $color};
  gap: 1rem;
  padding: 1.25rem;
  @media (min-width: 1080px) {
    padding: var(--spacing-2x-large) var(--site-padding);
  }
  h2 {
    font-size: var(--font-size-large);
    margin: 0 auto;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const ArticleList = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  justify-content: center;
  gap: 1.5rem;
  @media (min-width: 1080px) {
    gap: 2.5rem;
  }
`;

const Article = styled.article`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  a {
    color: inherit;
    width: fit-content;
    transform: translateX(-1rem);
    &:hover {
      text-decoration: underline;
      color: inherit;
    }
  }
  h3 {
    margin: 0;
  }
`;

const ModuleFooter = styled.footer`
  display: flex;
  justify-content: center;
`;

const CustomerCasesModule = ({ moduleData }: { moduleData: CustomerCasesFragment }) => {
  const { t } = useTranslation();
  const customerCases = (moduleData.rows?.nodes ?? []) as Extract<
    NonNullable<CustomerCasesFragment['rows']>['nodes'][number],
    { __typename: 'CustomerCase' }
  >[];
  const textColor = chooseLightOrDarkTextColor(moduleData.bgColor);

  return (
    <ModuleContainer $background={moduleBackgroundToCSS(moduleData.bgColor)} $color={textColor}>
      <h2>{moduleData.title}</h2>
      <ArticleList>
        {customerCases.map(customerCase => (
          <Article key={customerCase.id}>
            <PostImage data={customerCase?.featuredImage?.node as ImageFragment} />
            <h3>{customerCase.title}</h3>
            <div dangerouslySetInnerHTML={{ __html: customerCase?.excerpt || '' }} />
            {customerCase.link && (
              <LinkButton href={customerCase.link} variant="text">
                {t('common_read-more')}
              </LinkButton>
            )}
          </Article>
        ))}
      </ArticleList>
      {moduleData.links && moduleData.links.length > 0 && (
        <ModuleFooter>
          {moduleData.links.map((link, i) => (
            <LinkButton
              key={link?.link?.url || i}
              href={link?.link?.url || ''}
              variant={chooseButtonColorBasedOnBackground(moduleData.bgColor)}
            >
              {link?.link?.title}
            </LinkButton>
          ))}
        </ModuleFooter>
      )}
    </ModuleContainer>
  );
};

export default CustomerCasesModule;
