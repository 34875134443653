import { PageFragment } from '../../../wp-generated/types';
import LinkButton from '../../LinkButton';
import { chooseButtonColorBasedOnBackground, getColorForCheckmark } from '../getModuleColors';
import { Container, Kicker, ModuleFooter, Title, Wysiwyg } from './CommonStyles';

const HeroBg = ({ data }: { data: NonNullable<PageFragment['hero']>['heroBg'] }) => {
  return (
    <Container $color="var(--color-white)" $bgimage={data?.image?.node?.mediaItemUrl || undefined}>
      <div>
        <header>
          {data?.kicker && <Kicker>{data.kicker}</Kicker>}
          <Title dangerouslySetInnerHTML={{ __html: data?.title ?? '' }} />
        </header>
        <Wysiwyg
          dangerouslySetInnerHTML={{ __html: data?.description ?? '' }}
          $primaryColor={getColorForCheckmark(data?.bgColor)}
        />
      </div>
      {data?.links && data?.links.length > 0 && (
        <ModuleFooter>
          {data?.links.map((link, i) => (
            <LinkButton
              key={link?.link?.url || i}
              href={link?.link?.url || ''}
              variant={chooseButtonColorBasedOnBackground(data?.bgColor)}
            >
              {link?.link?.title}
            </LinkButton>
          ))}
        </ModuleFooter>
      )}
    </Container>
  );
};

export default HeroBg;
