import { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { CustomerCasePageFragment, PostPageFragment } from '../../wp-generated/types';
import { getModule } from '../CmsPage';
import { ArchivePagesMetadata } from '../Metadata';

const Container = styled.section`
  --aip-img-wrap-margin: 16rem;
  --aip-neg-img-wrap-margin: calc(-1 * var(--aip-img-wrap-margin));
  --aip-default-padding: 1.25rem;
  --aip-max-width: 800px;
  --customer-case-header-max-width: 1125px;
  --post-header-max-width: var(--aip-max-width);
  position: relative;
`;

const Header = styled.header<{ $background: string; $color: string; $isPost: boolean }>`
  background-color: ${({ $background }) => $background};
  color: ${({ $color }) => $color};
  padding: 6rem 1.25rem 3rem 1.25rem;
  @media (min-width: 1080px) {
    ${({ $isPost }) =>
      $isPost
        ? 'padding: calc(var(--spacing-large) * 2) calc((100vw - var(--post-header-max-width)) / 2);'
        : 'padding: calc(var(--spacing-large) * 2) calc((100vw - var(--customer-case-header-max-width)) / 2);'}
  }

  ${({ $isPost }) =>
    !$isPost &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.25rem;
      align-items: center;
    `}

  h1 {
    width: 90%;
    margin: 0 auto;
    font-size: 2.2rem;
    font-weight: bold;
    letter-spacing: -0.1rem;
    line-height: 1.2;
  }
`;

const ImgWrapper = styled.div<{ $isPost: boolean }>`
  aspect-ratio: 16 / 9;
  overflow: hidden;
  margin-top: 1.25rem;
  @media (min-width: 1080px) {
    ${({ $isPost }) =>
      $isPost &&
      css`
        margin-bottom: var(--aip-neg-img-wrap-margin);
        position: relative;
      `}
  }
`;

const Img = styled.img`
  width: 100%;
  object-fit: cover;
`;

const Wysiwyg = styled.div<{ $isPost: boolean }>`
  padding: 3rem 1.25rem;
  @media (min-width: 1080px) {
    ${({ $isPost }) =>
      $isPost
        ? css`
            padding: calc(var(--aip-img-wrap-margin) - var(--aip-default-padding))
              calc((100vw - var(--aip-max-width)) / 2);
          `
        : css`
            padding: 6rem calc((100vw - var(--aip-max-width)) / 2);
          `}
  }
  & h2 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 2.5rem 0 1.25rem 0;
  }

  & h3 {
    font-size: 1.375rem;
    font-weight: 700;
    margin: 2.5rem 0 1.25rem 0;
  }

  & > p:first-child {
    font-size: 1.2rem;
    @media (min-width: 1080px) {
      font-size: 1.5rem;
    }
    font-weight: 600;
  }
  & p {
    margin-bottom: 1.25rem;
  }
  & a {
    color: var(--link-color);
    text-decoration: underline;
  }
  & blockquote {
    position: relative;
    margin: 0;
    & p {
      font-style: italic;
      color: var(--color-gray);
      line-height: 1.2;
      font-size: 1.25rem;
      width: 90%;
      margin-left: 10%;
      @media (min-width: 1080px) {
        font-size: 1.5rem;
        width: 85%;
        margin-left: 15%;
      }
    }
  }
  & blockquote::before {
    position: absolute;
    content: url('data:image/svg+xml;base64,CiAgPHN2ZwogICAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogICAgZmlsbD0ibm9uZSIKICAgIHZpZXdCb3g9IjAgMTUwIDEwMDAgNzUwIgogICAgc3Ryb2tlV2lkdGg9IjEuNSIKICAgIHN0cm9rZT0iI2ZmNDQ3MyIKICA+CiAgICA8cGF0aAogICAgICBzdHJva2VMaW5lY2FwPSJyb3VuZCIKICAgICAgc3Ryb2tlTGluZWpvaW49InJvdW5kIgogICAgICBmaWxsPSIjZmY0NDczIgogICAgICBkPSJNOTY1LjIgMjEzVjU5Ni41SDk2NS4yVjYzMS4zIDczNS43TDgzNi41IDYwNyA4MjYuMSA1OTYuNUg4MTEuMyA1ODEuN1YyMTNIOTY1LjJNMTAwMCAxNzguM0g1NDdWNjMxLjNIODExLjNMMTAwMCA4MjBWNjMxLjNIMTAwMFYxNzguM0wxMDAwIDE3OC4zWk00MTguMyAyMTMuOVY1OTcuNEg0MTguM1Y2MzIuMiA3MzYuNUwyODkuNiA2MDcuOCAyNzkuMSA1OTcuNEgyNjQuMyAzNC44VjIxMy45SDQxOC4zTTQ1MyAxNzkuMUgwVjYzMi4ySDI2NC4zTDQ1MyA4MjAuOVY2MzIuMkg0NTNWMTc5LjFMNDUzIDE3OS4xWiIKICAgIC8+CiAgPC9zdmc+Cg==');
    z-index: 1;
    color: var(--color-primary);
    width: clamp(2rem, 5vw, 6vw);
    height: clamp(2rem, 5vw, 6vw);
    top: 0;
    left: 0;
  }
`;

const Breadcrumbs = styled.nav<{ $color: string }>`
  color: ${({ $color }) => $color};
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  font-size: 0.75rem;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    gap: 0.5rem;
  }
  li {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  a:hover {
    text-decoration: underline;
  }
`;

const isPostPage = (
  page: PostPageFragment | CustomerCasePageFragment,
): page is PostPageFragment => {
  return (page as PostPageFragment).__typename === 'Post';
};

interface ArchiveItemPageProps {
  page: PostPageFragment | CustomerCasePageFragment;
  locale?: string;
}

const ArchiveItemPage = ({ page, locale }: ArchiveItemPageProps) => {
  const { seo } = page;
  const numberOfBreadcrumbs = seo?.breadcrumbs?.length || 0;
  const isPost = isPostPage(page);
  const background = isPost ? 'var(--color-purple)' : 'var(--color-orange)';
  const color = isPost ? 'var(--color-white)' : 'var(--color-deep-purble)';

  return (
    <>
      <ArchivePagesMetadata locale={locale} seo={seo} searchResultPage={false} />
      <Container>
        {seo?.breadcrumbs && seo.breadcrumbs.length > 0 && (
          <Breadcrumbs aria-label="breadcrumbs" $color={color}>
            <ul>
              {seo.breadcrumbs.map((b, index) => {
                const lastBreadcrumb = numberOfBreadcrumbs - 1 === index;
                return (
                  <li key={index} aria-current={lastBreadcrumb ? 'page' : undefined}>
                    {!lastBreadcrumb ? <a href={b?.url || ''}>{b?.text} </a> : b?.text}
                    {!lastBreadcrumb ? '/' : ''}
                  </li>
                );
              })}
            </ul>
          </Breadcrumbs>
        )}
        <article>
          <Header $background={background} $color={color} $isPost={isPost}>
            <h1>{page.title}</h1>
            <ImgWrapper $isPost={isPost}>
              <Img src={page.featuredImage?.node?.mediaItemUrl || ''} alt="" />
            </ImgWrapper>
          </Header>
          <Wysiwyg dangerouslySetInnerHTML={{ __html: page.content || '' }} $isPost={isPost} />
        </article>
      </Container>
      {!isPostPage(page) &&
        page.modules?.flexibleModules?.map((moduleData, i) => (
          <Fragment key={i}>{getModule(moduleData)}</Fragment>
        ))}
    </>
  );
};

export default ArchiveItemPage;
