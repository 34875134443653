import styled from 'styled-components';
import { NumbersFragment } from '../../../wp-generated/types';
import LinkButton from '../../LinkButton';
import { moduleBackgroundToCSS } from '../getModuleColors';

const Container = styled.section<{ $background: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: var(--section-padding) var(--site-padding);
  color: var(--color-white);
  gap: var(--spacing-large);
  background: ${({ $background }) => $background};
`;

const Title = styled.h2`
  font-size: 15px;
  font-weight: var(--font-weight-extra-bold);
  letter-spacing: 1px;
  line-height: 22px;
  text-transform: uppercase;
`;

const Numbers = styled.dl<{ $numberOfNumbers: number }>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  justify-content: center;
  gap: var(--spacing-large) var(--spacing-medium);
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const Number = styled.dt`
  font-size: 44px;
  font-weight: var(--font-weight-bold);
  letter-spacing: -2px;
  line-height: 53px;
  margin-bottom: var(--spacing-small);
`;

const NumberDescription = styled.dd`
  margin-inline-start: unset;
  max-width: 60ch;
  p {
    text-align: center;
  }
`;

const Button = styled(LinkButton)`
  padding: 9px 18px;
  line-height: 18px;
  width: fit-content;
  display: flex;
  justify-content: center;
`;

const NumbersModule = ({ moduleData }: { moduleData: NumbersFragment }) => {
  const numbers = moduleData.numbers || [];

  return (
    <Container $background={moduleBackgroundToCSS(moduleData.bgColor)}>
      {moduleData.title && <Title>{moduleData.title}</Title>}
      <Numbers $numberOfNumbers={moduleData.numbers?.length || 0}>
        {numbers.map((number, index) => (
          <Wrapper key={`${number}-${index}`}>
            <Number>{number?.value}</Number>
            <NumberDescription dangerouslySetInnerHTML={{ __html: number?.description || '' }} />
          </Wrapper>
        ))}
      </Numbers>
      {moduleData.links?.map(
        (link, index) =>
          link?.link?.url && (
            <Button
              key={index}
              href={link?.link?.url}
              target={link?.link?.target || ''}
              variant="purple"
            >
              {link?.link?.title}
            </Button>
          ),
      )}
    </Container>
  );
};

export default NumbersModule;
