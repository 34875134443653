import { PageFragment } from '../../../wp-generated/types';
import LinkButton from '../../LinkButton';
import {
  chooseButtonColorBasedOnBackground,
  chooseLightOrDarkTextColor,
  getColorForCheckmark,
  moduleBackgroundToCSS,
} from '../getModuleColors';
import { Container, Kicker, ModuleFooter, Title, Wysiwyg } from './CommonStyles';

const HeroCenter = ({ data }: { data: NonNullable<PageFragment['hero']>['heroCenter'] }) => {
  return (
    <Container
      $backgroundcolor={moduleBackgroundToCSS(data?.bgColor)}
      $color={chooseLightOrDarkTextColor(data?.bgColor)}
      $center={true}
    >
      <header>
        {data?.kicker && <Kicker>{data.kicker}</Kicker>}
        <Title dangerouslySetInnerHTML={{ __html: data?.title ?? '' }} />
      </header>
      <Wysiwyg
        dangerouslySetInnerHTML={{ __html: data?.description ?? '' }}
        $primaryColor={getColorForCheckmark(data?.bgColor)}
      />

      {data?.links && data?.links.length > 0 && (
        <ModuleFooter>
          {data?.links.map((link, i) => (
            <LinkButton
              key={link?.link?.url || i}
              href={link?.link?.url || ''}
              variant={chooseButtonColorBasedOnBackground(data?.bgColor)}
            >
              {link?.link?.title}
            </LinkButton>
          ))}
        </ModuleFooter>
      )}
    </Container>
  );
};

export default HeroCenter;
