import { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { getButtonStyling } from '../Button/styling';
import FormTitleBanner from '../FormTitleBanner';

const FormField = css`
  background-color: var(--form-field-bg);
  border-color: var(--form-field-border-color);
  border-radius: var(--form-field-border-radius);
  border-style: var(--form-field-border-style);
  border-width: var(--form-field-border-width);
  padding: 16px var(--spacing-small);
  line-height: 23px;
  width: 100%;
  margin-bottom: var(--spacing-small);
  transition: all var(--anim-speed) var(--easing);
  display: block;

  &::placeholder {
    font-style: italic;
  }

  &:focus {
    background-color: var(--form-field-focus-bg);
    outline: none;

    .span {
      display: block;
    }
  }
`;

const HubspotFormWrapper = styled.div`
  position: relative;
  background-color: var(--color-white);
  padding: var(--card-padding) var(--card-padding);
  form {
    * {
      color: var(--text-color);
    }
    font-family: var(--body-font-family);
    display: flex;
    flex-direction: column;
    gap: 10px;
    letter-spacing: -0.15px;

    div {
      position: relative;
      margin: 0;

      > label {
        display: none;
      }
    }

    span {
      a {
        color: var(--link-color);
      }
    }

    input {
      ${FormField};
      margin: 0;
      &.error {
        border-color: var(--color-error);
        background-color: var(--form-field-focus-bg);
      }
    }

    select {
      ${FormField};
      font-size: 15px;
      letter-spacing: 0.15px;
      line-height: 22px;
      appearance: none;
      position: relative;
      background-image: url('data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMTAwMCAxMDAwIiBzdHJva2VXaWR0aD0iMS41IiBzdHJva2U9ImN1cnJlbnRDb2xvciI+CiAgPHBhdGggc3Ryb2tlTGluZWNhcD0icm91bmQiIHN0cm9rZUxpbmVqb2luPSJyb3VuZCIgZmlsbD0iY3VycmVudENvbG9yIgogICAgZD0iTTMyIDIyOEM3NCAxODYgMTQ0IDE4NiAxODYgMjI4TDUwMCA1NDIgODE0IDIyOEM4NTYgMTg2IDkyNiAxODYgOTY4IDIyOCAxMDEwIDI3MCAxMDEwIDM0MCA5NjggMzgyTDU3NiA3NzJDNTM0IDgxNCA0NjQgODE0IDQyMiA3NzJMMzIgMzgwQy0xMCAzMzgtMTAgMjcwIDMyIDIyOFoiIC8+Cjwvc3ZnPgo=');
      background-position: right var(--form-field-padding-x, 1.25rem) top 50%;
      background-repeat: no-repeat;
      background-size: 8px;
      margin: 0;
    }

    input[type='submit'] {
      ${getButtonStyling({ $variant: 'primary' })};
      font-size: var(--font-size-large);
      padding: 20px var(--spacing-medium);
      margin-top: var(--spacing-medium);
      margin-bottom: var(--spacing-small);
      text-align: center;
      display: flex;
      justify-content: center;
      width: fit-content;

      @media (max-width: 1079px) {
        width: 100%;
      }
    }

    ul {
      margin: 0;
      margin-top: 5px;
      padding: 0;
      width: 100%;
      text-align: right;
      color: var(--color-error);
      font-size: var(--font-size-small);
      font-style: italic;
      display: flex;
      flex-direction: row;
    }

    li {
      list-style: none;
      width: 100%;
      text-align: right;
      opacity: 1;
    }

    .hs-form-booleancheckbox {
      display: flex;
      flex-direction: row;
      text-align: left;
      font-style: normal;
      font-size: 15px;
      display: inline;
      margin-left: 5px;
      vertical-align: middle;
      color: var(--text-color);
      line-height: 20px;

      a {
        color: var(--link-color);
      }

      input {
        width: 13px;
        display: inline;
        vertical-align: middle;
        accent-color: var(--color-primary);
        margin-right: var(--spacing-small);
      }

      span {
        font-size: 15px;
        color: var(--text-color);
      }

      label {
        opacity: 1;
      }
    }
  }
`;

export const HubspotForm = ({
  hsFormId,
  title,
  background,
}: {
  hsFormId?: string | null;
  title?: string | null;
  background: string | null;
}) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    script.addEventListener('load', () => {
      interface Hbspt {
        forms: {
          create: (options: { portalId: string; formId: string; target: string }) => void;
        };
      }

      const hbspt = (window as unknown as { hbspt?: Hbspt }).hbspt;

      if (hbspt && hsFormId) {
        hbspt.forms.create({
          portalId: '5540930', // todo add as env-variable?
          formId: hsFormId,
          target: '#hubspotForm',
        });
      }
    });
    document.body.appendChild(script);
  }, [hsFormId]);

  return (
    <HubspotFormWrapper>
      {title && <FormTitleBanner title={title} background={background} />}
      <div id="hubspotForm" />
    </HubspotFormWrapper>
  );
};
