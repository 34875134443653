import { PageFragment } from '../../../wp-generated/types';
import LinkButton from '../../LinkButton';
import {
  chooseButtonColorBasedOnBackground,
  chooseLightOrDarkTextColor,
  moduleBackgroundToCSS,
} from '../getModuleColors';
import {
  Container,
  Image,
  ImageWrapper,
  Kicker,
  ModuleFooter,
  Title,
  Wysiwyg,
} from './CommonStyles';

const Hero = ({ data }: { data: NonNullable<PageFragment['hero']>['hero'] }) => {
  return (
    <Container
      $backgroundcolor={moduleBackgroundToCSS(data?.bgColor)}
      $color={chooseLightOrDarkTextColor(data?.bgColor)}
      $columns={data?.image?.node?.mediaItemUrl ? '2' : '1'}
    >
      <div>
        <header>
          {data?.kicker && <Kicker>{data.kicker}</Kicker>}
          <Title dangerouslySetInnerHTML={{ __html: data?.title ?? '' }} />
        </header>
        <Wysiwyg dangerouslySetInnerHTML={{ __html: data?.description ?? '' }} />
      </div>
      {data?.image?.node?.mediaItemUrl && (
        <ImageWrapper>
          <Image
            src={data?.image?.node?.mediaItemUrl}
            alt=""
            srcSet={data?.image?.node?.srcSet || undefined}
            sizes={data?.image?.node?.sizes || undefined}
          />
        </ImageWrapper>
      )}
      {data?.links && data?.links.length > 0 && (
        <ModuleFooter>
          {data?.links.map((link, i) => (
            <LinkButton
              key={link?.link?.url || i}
              href={link?.link?.url || ''}
              variant={chooseButtonColorBasedOnBackground(data?.bgColor)}
            >
              {link?.link?.title}
            </LinkButton>
          ))}
        </ModuleFooter>
      )}
    </Container>
  );
};

export default Hero;
