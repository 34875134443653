import styled, { css } from 'styled-components';
import { PricingPlansFragment } from '../../../wp-generated/types';
import { liBeforeStyles, usePrimaryColor } from '../getPrimaryColorForCheckmark';

const Container = styled.section`
  display: flex;
  justify-content: center;
  margin: var(--section-padding) auto;
`;

const ContentContainer = styled.div`
  width: var(--site-width);
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: var(--spacing-large);
  column-gap: 26px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 1080px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const dividingLine = css`
  background: var(--text-color);
  content: '';
  display: block;
  height: 100%;
  opacity: 0.15;
  width: 2px;
  margin-left: 26px;
`;

const PricingPlanContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (min-width: 768px) and (max-width: 1080px) {
    &:not(:nth-child(2n)):after {
      ${dividingLine}
    }
  }
  @media (min-width: 1080px) {
    &:not(:last-child):after {
      ${dividingLine}
    }
  }
`;

const PricingPlan = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.div`
  font-size: var(--font-size-x-large);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--spacing-small);
`;

const Description = styled.div<{ $primaryColor: string }>`
  font-size: var(--font-size-large);

  a {
    color: var(--link-color);
  }

  ul {
    padding: 0;
    margin: var(--spacing-medium) 0;
    display: block;
  }

  li {
    margin-bottom: var(--spacing-small);
    list-style: none;
    position: relative;
    padding-left: 30px;
    font-size: 15px;
  }

  ${liBeforeStyles}
`;

const PricingPlans = ({ moduleData }: { moduleData: PricingPlansFragment }) => {
  const containerRefAndPrimaryColor = usePrimaryColor();
  return (
    <Container ref={containerRefAndPrimaryColor.ref}>
      <ContentContainer>
        {moduleData?.pricingPlans?.map((pricingPlan, index) => (
          <PricingPlanContainer key={index}>
            <PricingPlan>
              <Title>{pricingPlan?.title}</Title>
              <Description
                dangerouslySetInnerHTML={{ __html: pricingPlan?.description || '' }}
                $primaryColor={containerRefAndPrimaryColor.color}
              />
            </PricingPlan>
          </PricingPlanContainer>
        ))}
      </ContentContainer>
    </Container>
  );
};

export default PricingPlans;
